import React, { useEffect, useState, useRef } from 'react';
import speakers from "../../assets/summit/bio_book.pdf"
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`;

const Speakers: React.FC = () => {
    const [height, setHeight] = useState<null | number>(0);
    const [width, setWidth] = useState<null | number>(0);
    const elementRef = useRef(null);

    const pages = [];
    for (let i = 1; i < 29; i++) {
        pages.push(i)
    }

    useEffect(() => {
        setHeight(elementRef.current.offsetHeight);
        setWidth(elementRef.current.offsetWidth)
    }, []);

    return (
        <div className='h-screen w-screen bg-off-white' ref={elementRef}>
            <Document
                className="h-full w-full"
                file={speakers}
            >
                {
                    pages.map((page) => {
                        if (height < width) {
                            return (
                                <Page
                                    pageNumber={page}
                                    canvasBackground="#F5F5F5"
                                    height={height}
                                />
                            )
                        }
                        else return (
                                <Page
                                    pageNumber={page}
                                    canvasBackground="#F5F5F5"
                                    width={width}
                                />
                            )
                })
                }
            </Document>
        </div>

    )
}

export default Speakers